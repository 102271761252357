<script lang="ts" setup>
import TermPopup from '~/components/setting/TermPopup.vue'
import PrivacyPopup from '~/components/setting/PrivacyPopup.vue'
import { useAppStore } from '~/stores/app'
import { getUserDevice, moveTo } from '~/common/Common'
import { DEVICE_TYPE } from '~/enum/enum'
import { pushUseInfo, updatePushUseInfo } from '~/apis/setting'
import type { PushUseInfo } from '~/apis/setting/types'
import {
  openSetting,
  refreshEnable,
  reqPushPermission,
} from '~/plugins/message'
import { storeToRefs } from 'pinia'

const app = useAppStore()
const { pushPermission } = storeToRefs(app)
const emit = defineEmits(['close'])
const step = ref(0)
const isNative = ref(false)
const data = ref<PushUseInfo[]>()
const env = import.meta.env.VITE_ENV
onMounted(async () => {
  refreshEnable(false)
  reqPushPermission()
  const app = useAppStore()
  const device = getUserDevice(app.appType)
  if (
    device === DEVICE_TYPE.IOS_T_APP ||
    device === DEVICE_TYPE.ANDROID_T_APP ||
    device === DEVICE_TYPE.IOS_M_APP ||
    device === DEVICE_TYPE.ANDROID_M_APP
  ) {
    isNative.value = true
  } else {
    isNative.value = false
  }
  await getPushInfoList()
})
onUnmounted(() => {
  refreshEnable(true)
})

const getPushInfoList = async () => {
  await getPushInfo()
  if (isNative.value && !pushPermission.value) {
    data?.value?.forEach((item) => {
      item.USED_TYPE = 'N'
    })
  }
}

const getPushInfo = async () => {
  let idx = 0
  if (app.userInfo?.P_IDX ?? '' != '') {
    idx = app.userInfo?.P_IDX ?? 0
  } else {
    idx = app.userInfo?.UM_IDX ?? 0
  }
  const res = await pushUseInfo({ UM_IDX: idx })
  data.value = res.data.data
}
const clickHandler = async (item: PushUseInfo) => {
  if (isNative.value) {
    if (!pushPermission.value) {
      return
    }
  }

  let useType = ''
  if (item.USED_TYPE === 'Y') {
    item.USED_TYPE = 'N'
  } else {
    item.USED_TYPE = 'Y'
  }

  let idx = 0
  if (app.userInfo?.P_IDX ?? '' != '') {
    idx = app.userInfo?.P_IDX ?? 0
  } else {
    idx = app.userInfo?.UM_IDX ?? 0
  }

  await updatePushUseInfo({
    UM_IDX: idx,
    TOPIC_CD: item.TOPIC_CD,
    USED_TYPE: item.USED_TYPE,
  })
  await getPushInfoList()
}
const openSettingClick = () => {
  openSetting()
}

watch(
  () => pushPermission.value,
  async () => {
    await getPushInfoList()
  },
)
</script>

<template>
  <!-- .layerWrap -->
  <div id="layerWrap" class="layerWrap show active" style="">
    <div v-if="step === 0" class="appSet appSetLayer">
      <section>
        <header class="alignLeft">
          <h2>앱 설정</h2>
          <a class="btnLayerClose cursor-pointer" @click="emit('close')"
            >레이어 클로즈</a
          >
        </header>
        <main>
          <div v-if="isNative" class="tit">
            <dl>
              <dt>앱 푸시 알림</dt>
              <dd>알림을 받으려면 기기 알림 설정에서 알림을 켜주세요</dd>
            </dl>
            <div class="btnStyleArea row">
              <a class="colorBl samll" @click="openSettingClick()">설정</a>
            </div>
          </div>
          <div v-if="isNative" class="cont">
            <div v-for="(item, index) in data" :key="index">
              <dl>
                <dt>{{ item.TOPIC_TITLE }} 알림</dt>
                <dd>
                  {{ item.TOPIC_CD === '3' ? '탑승할 ' : ''
                  }}{{ item.TOPIC_TITLE
                  }}{{
                    item.TOPIC_CD === '2' ? '발행을 ' : '정보를 '
                  }}알려드려요
                </dd>
              </dl>
              <div class="btn">
                <a
                  :class="item.USED_TYPE === 'Y' ? 'active' : ''"
                  class="toggle"
                  @click="clickHandler(item)"
                  ><span>토글버튼</span>
                </a>
              </div>
            </div>
          </div>
          <div class="bottomCont">
            <ul>
              <li>
                <a class="cursor-pointer" @click="(step = 1)"> 이용약관 </a>
              </li>
              <li>
                <a class="cursor-pointer" @click="(step = 2)">
                  개인정보 처리방침
                </a>
              </li>
              <li v-if="env === 'DEV' || env === 'LOCAL'">
                <a class="cursor-pointer" @click="moveTo('/payment/edu')">
                  결제 테스트
                </a>
              </li>
            </ul>
            <p>
              버전 정보
              <span>{{ app.version }}</span>
            </p>
          </div>
        </main>
      </section>
    </div>
    <TermPopup v-if="step === 1" @back="(step = 0)" @close="emit('close')" />
    <PrivacyPopup v-if="step === 2" @back="(step = 0)" @close="emit('close')" />
  </div>
  <!-- //.layerWrap -->
</template>
